<template>
  <div
    class="columns m-0 box mt-5"
    @click="
      () => {
        this.$router.push({
          name: 'ItemDetail',
          query: { id: item._id, type: item.type }
        });
      }
    "
    style="cursor:pointer"
  >
    <div class="column is-3 has-text-centered">
      <div v-if="item.type === 'image'">
        <img
          :src="item.attachmentURL"
          width="100%"
          style="pointer-events: none;"
        />
      </div>
      <div v-else-if="item.type === 'map'">
        <img v-if="item.attachmentURL" :src="item.attachmentURL" width="100%" />
        <img v-else src="@/assets/img/dataset_icon.svg" width="100%" />
      </div>
      <div
        v-else-if="item.type === 'video' && item.attachmentURL.includes(`v=`)"
        class="video-thumbnail"
      >
        <div class="video-overlay ml-2">
          <i class="mdi mdi-play-circle"></i>
        </div>
        <img
          :src="
            'https://img.youtube.com/vi/' +
              item.attachmentURL.split(`v=`)[1].split('&')[0] +
              '/default.jpg'
          "
          width="100%"
        />
      </div>
      <div v-else-if="item.type === 'video'" class="video-thumbnail">
        <img src="@/assets/img/video_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'audio'">
        <img src="@/assets/img/audio_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'dataset'">
        <img src="@/assets/img/dataset_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'PrintedBook'">
        <img v-if="item.coverPhoto" :src="item.coverPhoto" width="100%" />
        <img v-else src="@/assets/img/book_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'eBook'">
        <img v-if="item.coverPhoto" :src="item.coverPhoto" width="100%" />
        <img v-else src="@/assets/img/book_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'Research'">
        <img src="@/assets/img/research_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'eResearch'">
        <img src="@/assets/img/research_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'Journal'">
        <img src="@/assets/img/journal_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'eJournal'">
        <img src="@/assets/img/journal_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'Newspaper'">
        <img src="@/assets/img/newspaper_icon.svg" width="100%" />
      </div>
      <div v-else-if="item.type === 'eNewspaper'">
        <img src="@/assets/img/newspaper_icon.svg" width="100%" />
      </div>
      <div v-else>
        <img src="@/assets/img/dataset_icon.svg" width="100%" />
      </div>
    </div>
    <div class="column">
      <div class="columns m-0">
        <div class="column p-0">
          <p class="text-subtitle bold">
            {{ item.title }}
          </p>
          <p v-if="item.authors && !item.source">
            {{
              Array.isArray(item.authors)
                ? item.authors.join(", ")
                : item.authors | filter
            }}
          </p>
          <p v-if="!item.authors && item.source">
            {{
              Array.isArray(item.source)
                ? item.source.join(", ")
                : item.source | filter
            }}
          </p>
        </div>
        <div v-if="item.premiumContent" classs="column p-0 is-narrow mt-2">
          <b-tag
            class="mr-2"
            type="is-info"
            size="is-medium"
            style="background-color: #f5bc42; color:black"
            rounded
          >
            <i class="mdi mdi-crown"></i>
            Premium
          </b-tag>
        </div>
        <div class="column p-0 is-narrow mt-2">
          <b-tag
            type="is-info"
            size="is-medium"
            style="background-color: #9e0607"
            rounded
          >
            {{ item.type | formatType | capitalize }}
          </b-tag>
        </div>
      </div>
      <p class="mt-5">
        {{
          item.description
            ? item.description
            : "Description goes here" | limitTo(200)
        }}
      </p>
      <div class="mt-5">
        <span
          v-for="(keyword, index) in item.keywords.slice(0, 3)"
          :key="index"
        >
          <a
            style="no-decor"
            :href="
              '/search?searchQuery=' +
                keyword +
                '&searchBy=keywords&filterBy=' +
                item.type
            "
          >
            <b-tag
              v-if="keyword != ''"
              style="background-color: #9e060733;color:#9e0607"
              type="is-info is-light"
              size="is-medium"
              class="mr-2 mt-2"
              rounded
            >
              {{ keyword }}
            </b-tag>
          </a>
        </span>
        <span v-if="item.keywords.length > 3">
          + {{ item.keywords.length - 3 }} more
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemCard",
  props: {
    item: Object
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.video-overlay {
  position: absolute;
  color: #fff;
  opacity: 0.8;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  font-size: 32px;
}
</style>
