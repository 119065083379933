<template>
  <div>
    <Navbar title="Explore" :hideSearch="false" />
    <div class="app-body">
      <div class="columns my-5 pb-5">
        <div class="column is-narrow">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <p class="text-subtitle bold">
                Browse by categories
              </p>
            </div>
            <div v-if="isPortrait" class="column is-narrow">
              <p
                class="mt-1"
                v-if="!showSideMenu"
                @click="() => (this.showSideMenu = true)"
              >
                <i
                  class="mdi mdi-arrow-down-drop-circle-outline mt-5"
                  style="font-size:32px;"
                ></i>
              </p>
              <p
                class="mt-1"
                v-if="showSideMenu"
                @click="() => (this.showSideMenu = false)"
              >
                <i
                  class="mdi mdi-arrow-up-drop-circle-outline mt-5"
                  style="font-size:32px;"
                ></i>
              </p>
            </div>
          </div>
          <div v-if="showSideMenu || !isPortrait">
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'all' }"
              @click="selectCategory('all')"
            >
              All Resources
            </p>
            <p class="text-subtitle mt-5">
              Library Catalogue
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'PrintedBook' }"
              @click="selectCategory('PrintedBook')"
            >
              Books
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'eBook' }"
              @click="selectCategory('eBook')"
            >
              E-Books
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'Research' }"
              @click="selectCategory('Research')"
            >
              Research
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'eResearch' }"
              @click="selectCategory('eResearch')"
            >
              E-Research
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'Journal' }"
              @click="selectCategory('Journal')"
            >
              Journal
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'eJournal' }"
              @click="selectCategory('eJournal')"
            >
              E-Journal
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'eNewspaper' }"
              @click="selectCategory('eNewspaper')"
            >
              E-Newspaper
            </p>
            <p class="text-subtitle mt-5">
              Digital Collection
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'image' }"
              @click="selectCategory('image')"
            >
              Images
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'video' }"
              @click="selectCategory('video')"
            >
              Videos
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'audio' }"
              @click="selectCategory('audio')"
            >
              Audio
            </p>
            <p
              class="mt-2 sidemenuItem"
              v-bind:class="{ sidemenuItemActive: category == 'dataset' }"
              @click="selectCategory('dataset')"
            >
              Datasets
            </p>
            <p
              class="mt-2 sidemenuItem mb-5"
              v-bind:class="{ sidemenuItemActive: category == 'map' }"
              @click="selectCategory('map')"
            >
              Maps
            </p>
          </div>
          <hr v-if="isPortrait" />
        </div>
        <div class="column is-1"></div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <p
                v-if="category === 'Research' || category === 'eResearch'"
                class="text-subtitle"
              >
                {{ category | formatType }}es
              </p>
              <p v-else-if="category === 'all'" class="text-subtitle">
                {{ category | formatType | capitalize }} Resources
              </p>
              <p v-else class="text-subtitle">
                {{ category | formatType | capitalize }}s
              </p>
            </div>
            <div class="column is-narrow">
              <div class="columns is-vcentered">
                <div class="column pr-0">
                  Sort by:
                </div>
                <div class="column">
                  <b-dropdown aria-role="list" v-model="sortName">
                    <template #trigger="{ active }">
                      <b-button
                        :label="sortName"
                        type="is-outline"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                      />
                    </template>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort(undefined)"
                      value="Relevance"
                    >
                      Relevance
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('title-asc')"
                      value="Title (A-Z)"
                    >
                      Title (A-Z)
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('title-desc')"
                      value="Title (Z-A)"
                    >
                      Title (Z-A)
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('dateCreated-desc')"
                      value="Date Added (Latest First)"
                      >Date Added (Latest First)
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('dateCreated-asc')"
                      value="Date Added (Oldest First)"
                      >Date Added (Oldest First)
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('publicationYear-desc')"
                      value="Publication Year (Latest First)"
                      >Publication Year (Latest First)
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSort('publicationYear-asc')"
                      value="Publication Year (Oldest First)"
                      >Publication Year (Oldest First)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              !isLoading &&
                digitalMediaPaginated &&
                digitalMediaPaginated.documents.length > 0
            "
          >
            <div v-for="item in digitalMediaPaginated.documents" :key="item.id">
              <ItemCard :item="item" />
            </div>
            <paginate
              v-model="pagination.currentPage"
              :page-count="pagination.totalPages"
              :click-handler="getContent"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div
            v-else-if="
              !isLoading &&
                digitalMediaPaginated &&
                digitalMediaPaginated.documents.length == 0
            "
          >
            <div class="columns">
              <div class="column has-text-centered not-found-container">
                <img src="@/assets/img/not_found_icon.svg" width="180px" />
                <p class="text-subtitle bold mt-2">No items to show</p>
              </div>
            </div>
          </div>
          <div v-else-if="isLoading">
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import ItemCard from "@/components/app/ItemCard";
import Loading from "@/components/app/Loading";
import paginate from "vuejs-paginate";

export default {
  name: "Explore",
  components: { Navbar, Footer, ItemCard, Loading, paginate },
  data() {
    return {
      isLoading: false,
      category: "image",
      sort: "publicationYear-desc",
      sortName: "Publication Year (Latest First)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      }
    };
  },
  computed: {
    digitalMediaPaginated() {
      return this.$store.state.digitalMediaPaginated;
    },
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    if (this.$route.query.page) {
      this.pagination.currentPage = parseInt(this.$route.query.page);
    }
    if (this.$route.query.category) {
      this.category = this.$route.query.category.toString();
    }
    await this.getContent();
  },
  methods: {
    selectCategory(category) {
      if (this.category !== category) {
        this.pagination.currentPage = 1;
        this.category = category;
        this.$router.replace({
          query: { ...this.$route.query, category: category }
        });
        this.getContent();
      }
    },
    selectSort(sort) {
      if (this.sort !== sort) {
        this.sort = sort;
        this.$router.replace({
          query: { ...this.$route.query, sort: sort }
        });
        this.getContent();
      }
    },
    async getContent() {
      window.scrollTo(0, 0);
      // searchQuery=geo&filterBy=subject&page=0&perpage=4&sortBy=title&sortOrder=asc&period=2012
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.pagination.currentPage
        }
      });
      this.isLoading = true;
      await this.$store.dispatch("getDigitalMediaPaginated", {
        filterResource: this.category,
        page: this.pagination.currentPage - 1,
        perpage: this.pagination.perPage,
        sortBy: this.sort ? this.sort.split("-")[0] : undefined,
        sortOrder: this.sort ? this.sort.split("-")[1] : undefined
      });
      if (this.digitalMediaPaginated.maxTotal) {
        this.pagination.totalPages = Math.ceil(
          this.digitalMediaPaginated.maxTotal / this.pagination.perPage
        );
      } else {
        this.pagination.totalPages = Math.ceil(
          this.digitalMediaPaginated.totalCount / this.pagination.perPage
        );
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.explore-box {
  background-color: #f8f8f8 !important;
}
.not-found-container {
  margin-top: 120px;
  margin-bottom: 120px;
}

.sidemenuItem {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.sidemenuItem a {
  color: $light-black;
}

.sidemenuItem:hover {
  background-color: $gray50;
  cursor: pointer;
}

.sidemenuItemActive {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: $primary-color;
  color: white !important;
  cursor: pointer;
}

.sidemenuItemActive:hover {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: $primary-color;
  color: white !important;
  cursor: pointer;
}

.sidemenuItemActive a {
  color: white !important;
}
</style>
